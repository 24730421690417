import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { highlightColumnColor } from '../../utils/dashboardCalculations';

class teamCard extends Component {
	componentDidMount() {}

	render() {
		const { player, userAllowed, sportsID } = this.props;
		const highlightColor = highlightColumnColor(player.team_gaming_score, 'cardHighlight');
		return (
			<div id='cardTeamRow' className='cardTeamRow'>
				<Col lg={12} className={`cardHighlight ${userAllowed && highlightColor} cardAdminTeamCol col-xs-12`}>
					<img src={player.team_logo} alt={`${player.team_name} logo`} className="teamLogo" />
					<div className='cardTeamDiv'>
					<p className='cardTeamName'>
						{player.player_name} ({player.position})
						<br></br>
						<br></br>
						{player.jersey}
					</p>
					</div>
				</Col>
			</div>

		);
	}
}

export default teamCard;
